/* This example requires Tailwind CSS v2.0+ */
import {Disclosure} from '@headlessui/react'
import Switcher from './Switcher'

const logo = require("../../src/assets/images/parqour_logo.png")
const logo_short = require("../../src/assets/images/parqour_logo_short.png")

export default function AppNavBar() {
    return (
        <Disclosure as="nav">
            {({open}) => (
                <>
                    <div className="mx-auto px-2 sm:px-6 lg:px-8 min-w-screen bg-white dark:bg-gray-900">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                {/* <Disclosure.Button
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only ">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                    )}
                                </Disclosure.Button> */}
                            </div>

                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">

                                <div className="flex-shrink-0 flex items-center dark:text-white text-gray-700">
                                    <img className="block lg:hidden h-20 w-auto mx-2 text-2xl dark:text-white text-black"
                                         src={logo}
                                         alt="Parqour"/>
                                    <img className="hidden lg:block h-28 w-auto  text-2xl dark:text-white text-black"
                                         src={logo_short}
                                         alt="Parqour"/>
                                </div>
                            </div>

                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <Switcher/>
                            </div>

                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    )
}