import React from "react";
import "./App.css";
import AppNavBar from "./components/AppNavBar";
import Form from "./components/Form";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export default class App extends React.Component {
    render() {
        return (
            <Router>
                <main>
                    <div className="min-h-screen bg-white  dark:bg-gray-800">
                        <ToastContainer hideProgressBar position="top-center" autoClose={3000} />
                        <AppNavBar />
                        <Routes>
                            <Route path="/parking/:parkingUid/payment" element={<Form />} />
                        </Routes>
                    </div>
                </main>
            </Router>
        );
    }
}
